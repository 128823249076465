@tailwind base;
@tailwind components;
@tailwind utilities;
@import "toastr";

.ant-spin-dot-item {
    background-color: green !important;
  }

  /* .ant-table-thead .ant-table-cell{
    background-color: #87b394 !important;
  }
  .ant-table-footer{
    background-color: #87b394 !important;
  } */

  .ant-btn-primary {
    background: rgb(95, 95, 193) !important;
  }

  